import { DesktopOnly } from '../DesktopOnly'
import { TopSearch } from '../Home/TopNavigation/TopSearch'
import Add from '../Home/TopNavigation/icons/Add'
import Coin from '../Home/TopNavigation/icons/Coin'
import {
    Balance,
    CoinBalanceContainer,
    CoinContainer,
} from '../Home/TopNavigation/styles'
import { LoginWrapper } from './sidebarStyle'
import { EventTracking } from '@/helpers'
import convertToIndonesianCurrency from '@/helpers/convertToIndonesianCurrency'
import { useLoadUserWallet } from '@/hooks/userLoadUserWallet'
import { setShowLoginPrompt } from '@/store/actions/player'
import { _showHeader } from '@/store/selectors/common'
import {
    Container,
    PageHeader,
    PageHeaderImage,
    PageHeaderLoginButton,
    PageHeaderLoginText,
} from '@/styles/styles.home'
import { SettingContainer } from '@/styles/styles.loginheader'
import { Tooltip } from 'antd'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const TopNavigation = dynamic(() => import('@/components/Home/TopNavigation'))

const PageLayout = (props) => {
    const router = useRouter()
    const { data: session, update, status } = useSession()
    const token = session?.token
    const showHeader = useSelector(_showHeader)

    const userInfo = session?.userInfo

    const dispatch = useDispatch()
    const navElement = useRef(false)

    const isTopNavVisible =
        router.pathname === '/' ||
        router.pathname === '/podcast' ||
        router.pathname === '/radio' ||
        router.pathname === '/audiobook' ||
        router.pathname === '/audioseries' ||
        router.pathname === '/registration' ||
        router.pathname === '/coin-detail'

    const isTopSearchVisible =
        router.pathname === '/' ||
        router.pathname === '/podcast' ||
        router.pathname === '/radio' ||
        router.pathname === '/audiobook' ||
        router.pathname === '/audioseries' ||
        router.pathname === '/registration' ||
        router.pathname === '/coin-detail' ||
        router.pathname.startsWith('/catalog') ||
        router.pathname.startsWith('/content') ||
        router.pathname.startsWith('/content') ||
        router.pathname.startsWith('/collection') ||
        router.pathname.startsWith('/purchased-episodes') ||
        router.pathname.startsWith('/my-profile')

    useEffect(() => {
        window.updateSession = update
    }, [update])

    const genrePage = props.genrePage

    const userName = userInfo?.userName
    const { wallet: userWallet } = useLoadUserWallet({ token, userName })

    const totalCoins =
        userWallet?.coinBalance + userWallet?.freeCoinBalance || 0

    return (
        <Container
            isFullScreenPlayer={props.isFullScreenPlayer}
            style={props.style || {}}
        >
            {showHeader && props.showHeader !== false && (
                <PageHeader ref={navElement} isTopNavVisible={isTopNavVisible}>
                    <PageHeaderImage>
                        <Link href={'/'} prefetch={false}>
                            <a>
                                <img
                                    src={'/img/new-noice-logo.svg'}
                                    alt="Noice Logo"
                                    loading="lazy"
                                />
                            </a>
                        </Link>
                    </PageHeaderImage>

                    <LoginWrapper>
                        {status === 'unauthenticated' ||
                        !session?.isLoggedin ? (
                            <PageHeaderLoginButton
                                onClick={() => {
                                    dispatch(setShowLoginPrompt(true))
                                }}
                            >
                                <PageHeaderLoginText>Masuk</PageHeaderLoginText>
                            </PageHeaderLoginButton>
                        ) : (
                            <CoinContainer>
                                <Tooltip
                                    overlayClassName="topUpCoinTooltip"
                                    title="Coins Detail"
                                    color={'white'}
                                    overlayInnerStyle={{
                                        borderRadius: '8px',
                                        color: 'var(--vb-ref-color-neutral-50, #333)',
                                        fontFamily: 'Readex Pro',
                                        fontDize: '14px',
                                        fontDtyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '160%',
                                        letterDpacing: '0.15px',
                                    }}
                                >
                                    <CoinBalanceContainer
                                        onClick={() => {
                                            router.push('/coin-detail')
                                        }}
                                    >
                                        <Coin />
                                        <Balance>
                                            {convertToIndonesianCurrency(
                                                totalCoins
                                            )}
                                        </Balance>
                                    </CoinBalanceContainer>
                                </Tooltip>

                                <Link href={'/coin'}>
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault()

                                            EventTracking(
                                                'Top up coins page from web opened',
                                                {
                                                    userType:
                                                        session?.isLoggedin
                                                            ? 'registered'
                                                            : 'guest',
                                                    loginAuth: session?.type,
                                                    userName: session?.userName,
                                                    userId: session?.userInfo
                                                        ?.id,
                                                    activeCoinBalance:
                                                        totalCoins,
                                                    source: 'coin icon ',
                                                    contentId: null,
                                                    contentTitle: null,
                                                    genres: null,
                                                    catalogId: null,
                                                    catalogTitle: null,
                                                    catalogSource: null,
                                                },
                                                session
                                            )

                                            router.push('/coin')
                                        }}
                                        style={{ marginBottom: '3px' }}
                                    >
                                        <Add />
                                    </a>
                                </Link>

                                {router.pathname.startsWith('/my-profile') && (
                                    <Link href="/settings" prefetch={false}>
                                        <a>
                                            <SettingContainer
                                                style={{ marginLeft: '6px' }}
                                                showSettings={true}
                                            >
                                                <img
                                                    alt="settings"
                                                    src="/img/settings.svg"
                                                    loading="lazy"
                                                />
                                            </SettingContainer>
                                        </a>
                                    </Link>
                                )}
                            </CoinContainer>
                        )}
                    </LoginWrapper>

                    {/* <PageHeaderVisitButtonContainer onClick={bukaAppHandler}>
                        <PageHeaderVisitButton>
                            <PageHeaderVisitText>Buka app</PageHeaderVisitText>
                        </PageHeaderVisitButton>
                    </PageHeaderVisitButtonContainer> */}

                    {/* <PageNavigationContainer>
                        <img
                            style={{ cursor: 'pointer' }}
                            src={'/img/dot.svg'}
                            alt="dot"
                            onClick={() =>
                                setShowNav((prevState) => !prevState)
                            }
                        />
                        {showNav && (
                            <PageNavigation>
                                <PageNavigationItem
                                    onClick={() => {
                                        setShowNav(false)
                                        router.push('policy')
                                    }}
                                >
                                    Kebijakan Privasi
                                </PageNavigationItem>
                                <PageNavigationItem
                                    onClick={() => {
                                        setShowNav(false)
                                        router.push('tnc')
                                    }}
                                >
                                    Syarat dan Ketentuan
                                </PageNavigationItem>
                            </PageNavigation>
                        )}
                    </PageNavigationContainer> */}
                </PageHeader>
            )}

            {showHeader &&
                props.showHeader !== false &&
                (isTopSearchVisible || genrePage) && (
                    <DesktopOnly>
                        <TopSearch />
                    </DesktopOnly>
                )}

            {showHeader && props.showHeader !== false && isTopNavVisible && (
                <TopNavigation
                    isTopNavVisible={isTopNavVisible}
                    genrePage={genrePage}
                />
            )}

            {props.children}
            {/* <div style={{ height: '150px' }}></div> */}
        </Container>
    )
}

export default PageLayout
